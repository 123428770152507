import React, { useState, useRef, useEffect } from "react"

import { useGlobalState } from "../global/global"
import { readFromCookie } from "../global/cookieHelper"

import "../styles/theme-button.css"

const ThemeButton = props => {
  const [state, dispatch] = useGlobalState()

  const ref = useRef(null)

  const setThemePreference = () => {
    if (state.lightThemeState !== undefined) {
      return state.lightThemeState
    }

    const currentThemeState = readFromCookie("theme") === "true" ? true : false

    dispatch({ lightThemeState: currentThemeState })

    return currentThemeState
  }

  useEffect(() => {
    const currentThemeState = setThemePreference()
    initTheme(currentThemeState)
  }, [ref])

  const setImageSrc = imageName => {
    if (props.location.pathname === "/") {
      return "/images/dark/" + imageName
    }

    if (state.lightThemeState) {
      return "/images/light/" + imageName
    }

    if (!state.lightThemeState) {
      return "/images/dark/" + imageName
    }
  }

  const handleIconStyle = isLightIcon => {
    if (isLightIcon && state.lightThemeState) {
      return { visibility: "visible" }
    }

    if (!isLightIcon && !state.lightThemeState) {
      return { visibility: "visible" }
    }

    if (isLightIcon && !state.lightThemeState) {
      return {}
    }

    if (!isLightIcon && state.lightThemeState) {
      return {}
    }

    return {}
  }

  const initTheme = currentThemeState => {
    if (currentThemeState) {
      document.documentElement.style.setProperty(
        "--body-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--header-default-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--theme-button-default-background-color",
        "rgb(0, 0, 0, 0.3)"
      )

      document.documentElement.style.setProperty(
        "--main-header-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty("--main-text-color", "#ffffff")
      document.documentElement.style.setProperty(
        "--main-button-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-button-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--product-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-text-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--product-line-color",
        "#d1d1d1"
      )
      document.documentElement.style.setProperty(
        "--product-subtext-color",
        "#7e8a93"
      )
      document.documentElement.style.setProperty(
        "--product-button-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-button-background-color",
        "#323470"
      )

      document.documentElement.style.setProperty(
        "--services-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--services-text-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--services-line-color",
        "#d1d1d1"
      )

      document.documentElement.style.setProperty(
        "--about-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--about-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--about-line-color",
        "#2a2c5f"
      )
      document.documentElement.style.setProperty(
        "--about-subtext-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--solutions-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--solutions-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--solutions-line-color",
        "#2a2c5f"
      )

      document.documentElement.style.setProperty(
        "--default-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty("--default-color", "#000000")
      document.documentElement.style.setProperty(
        "--default-subtext-color",
        "#7e8a93"
      )
      document.documentElement.style.setProperty(
        "--default-line-color",
        "#e0e0e0"
      )

      document.documentElement.style.setProperty(
        "--main-footer-top-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-footer-top-text-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-text-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--footer-default-top-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--footer-default-top-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-line-color",
        "#e0e0e0"
      )
    } else {
      document.documentElement.style.setProperty(
        "--body-background-color",
        "#1c222f"
      )

      document.documentElement.style.setProperty(
        "--header-default-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--theme-button-default-background-color",
        "rgb(255, 255, 255, 0.3)"
      )

      document.documentElement.style.setProperty(
        "--main-header-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty("--main-text-color", "#ffffff")
      document.documentElement.style.setProperty(
        "--main-button-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--main-button-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--product-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--product-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-line-color",
        "#d1d1d1"
      )
      document.documentElement.style.setProperty(
        "--product-subtext-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-button-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--product-button-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--services-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--services-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--services-line-color",
        "#d1d1d1"
      )

      document.documentElement.style.setProperty(
        "--about-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--about-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--about-line-color",
        "#202631"
      )
      document.documentElement.style.setProperty(
        "--about-subtext-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--solutions-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--solutions-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--solutions-line-color",
        "#202631"
      )

      document.documentElement.style.setProperty(
        "--default-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty("--default-color", "#ffffff")
      document.documentElement.style.setProperty(
        "--default-subtext-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--default-line-color",
        "#e0e0e0"
      )

      document.documentElement.style.setProperty(
        "--main-footer-top-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--main-footer-top-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-text-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--footer-default-top-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--footer-default-top-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-line-color",
        "#ffffff00"
      )
    }
  }

  const handleClickLightIcon = isLightIcon => {
    if (isLightIcon && !state.lightThemeState) {
      dispatch({ lightThemeState: isLightIcon })
      document.documentElement.style.setProperty(
        "--body-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--header-default-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--theme-button-default-background-color",
        "rgb(0, 0, 0, 0.3)"
      )

      document.documentElement.style.setProperty(
        "--main-header-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty("--main-text-color", "#ffffff")
      document.documentElement.style.setProperty(
        "--main-button-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-button-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--product-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-text-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--product-line-color",
        "#d1d1d1"
      )
      document.documentElement.style.setProperty(
        "--product-subtext-color",
        "#7e8a93"
      )
      document.documentElement.style.setProperty(
        "--product-button-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-button-background-color",
        "#323470"
      )

      document.documentElement.style.setProperty(
        "--services-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--services-text-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--services-line-color",
        "#d1d1d1"
      )

      document.documentElement.style.setProperty(
        "--about-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--about-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--about-line-color",
        "#2a2c5f"
      )
      document.documentElement.style.setProperty(
        "--about-subtext-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--solutions-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--solutions-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--solutions-line-color",
        "#2a2c5f"
      )

      document.documentElement.style.setProperty(
        "--default-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty("--default-color", "#000000")
      document.documentElement.style.setProperty(
        "--default-subtext-color",
        "#7e8a93"
      )
      document.documentElement.style.setProperty(
        "--default-line-color",
        "#e0e0e0"
      )

      document.documentElement.style.setProperty(
        "--main-footer-top-background-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-footer-top-text-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-text-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--footer-default-top-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--footer-default-top-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-background-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-line-color",
        "#e0e0e0"
      )
    }

    if (!isLightIcon && state.lightThemeState) {
      dispatch({ lightThemeState: false })
      document.documentElement.style.setProperty(
        "--body-background-color",
        "#1c222f"
      )

      document.documentElement.style.setProperty(
        "--header-default-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--theme-button-default-background-color",
        "rgb(255, 255, 255, 0.3)"
      )

      document.documentElement.style.setProperty(
        "--main-header-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty("--main-text-color", "#ffffff")
      document.documentElement.style.setProperty(
        "--main-button-color",
        "#323470"
      )
      document.documentElement.style.setProperty(
        "--main-button-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--product-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--product-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-line-color",
        "#d1d1d1"
      )
      document.documentElement.style.setProperty(
        "--product-subtext-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--product-button-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--product-button-background-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--services-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--services-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--services-line-color",
        "#d1d1d1"
      )

      document.documentElement.style.setProperty(
        "--about-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--about-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--about-line-color",
        "#202631"
      )
      document.documentElement.style.setProperty(
        "--about-subtext-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--solutions-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--solutions-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--solutions-line-color",
        "#202631"
      )

      document.documentElement.style.setProperty(
        "--default-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty("--default-color", "#ffffff")
      document.documentElement.style.setProperty(
        "--default-subtext-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--default-line-color",
        "#e0e0e0"
      )

      document.documentElement.style.setProperty(
        "--main-footer-top-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--main-footer-top-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--main-footer-bottom-text-color",
        "#ffffff"
      )

      document.documentElement.style.setProperty(
        "--footer-default-top-background-color",
        "#1c222f"
      )
      document.documentElement.style.setProperty(
        "--footer-default-top-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-background-color",
        "#282f3d"
      )
      document.documentElement.style.setProperty(
        "--footer-default-bottom-text-color",
        "#ffffff"
      )
      document.documentElement.style.setProperty(
        "--footer-default-line-color",
        "#ffffff00"
      )
    }
  }

  return (
    <div>
      <div ref={ref} className="theme-button">
        {!state.lightThemeState ? (
          <img
            onClick={() => handleClickLightIcon(true)}
            style={handleIconStyle(false)}
            className="theme-button__icon"
            src={setImageSrc("Dark.svg")}
            alt="light-theme"
          />
        ) : (
          <img
            onClick={() => handleClickLightIcon(false)}
            style={handleIconStyle(true)}
            className="theme-button__icon"
            src={setImageSrc("Light.svg")}
            alt="dark-theme"
          />
        )}
      </div>
    </div>
  )
}

export default ThemeButton
