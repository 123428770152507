import React from 'react';
import { navigate } from 'gatsby';
import ThemeButton from './theme-button';

import '../styles/header-icon-menu.css';

const HeaderIconMenu = props => {
    const handleClick = (id) => {
        props.closeMenu();
        navigate('/');
        var checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <div className='header-icon-menu'>
            <div className='header-icon-menu__menu'>
                <ul className='header-icon-menu__menu__items'>
                    <li onClick={() => handleClick('about-us')} className='header-icon-menu__menu__items__item'>ABOUT US</li>
                    <li onClick={() => handleClick('products')} className='header-icon-menu__menu__items__item'>PRODUCTS</li>
                    <li onClick={() => handleClick('solutions')} className='header-icon-menu__menu__items__item'>SOLUTIONS</li>
                    <li onClick={() => handleClick('services')} className='header-icon-menu__menu__items__item'>SERVICES</li>
                    <li onClick={() => navigate('/contact')} className='header-icon-menu__menu__items__item'>CONTACT</li>
                    <div className='header-icon-menu__theme_button'>
                        <ThemeButton horizontal={true} location={props.location} />
                    </div>
                </ul>
            </div>
        </div>
    );
}

export default HeaderIconMenu;