import React from "react"

import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import CookieConsent from "./cookie-concent"
import NewsModal from "./news-modal"

import "../styles/layout.css"

const Layout = props => {
  const isHomePage = url => {
    if (url === "/") {
      return true
    }

    return false
  }

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Dataunitor</title>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Caveat:700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header
        color={
          isHomePage(props.location.pathname)
            ? "var(--main-header-text-color)"
            : "var(--header-default-color)"
        }
        location={props.location}
      ></Header>
      <div>{props.children}</div>
      <Footer
        location={props.location}
        topColor={
          isHomePage(props.location.pathname)
            ? "var(--main-footer-top-text-color)"
            : "var(--footer-default-top-text-color)"
        }
        topBackgroundColor={
          isHomePage(props.location.pathname)
            ? "var(--main-footer-top-background-color)"
            : "var(--footer-default-top-background-color)"
        }
        bottomColor={
          isHomePage(props.location.pathname)
            ? "var(--main-footer-bottom-text-color)"
            : "var(--footer-default-bottom-text-color)"
        }
        bottomBackgroundColor={
          isHomePage(props.location.pathname)
            ? "var(--main-footer-bottom-background-color)"
            : "var(--footer-default-bottom-background-color)"
        }
      />
      <CookieConsent />
      {/* <NewsModal /> */}
    </div>
  )
}

export default Layout
