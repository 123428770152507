import React from "react"

import "../styles/cookie-concent.css"

import Button from "./button"
import { writeToCookie } from "../global/cookieHelper"
import { navigate } from "gatsby"
import { useGlobalState } from "../global/global"
import AnimationOnVisible from "../global/animation"

const CookieConcent = props => {
  const [state, dispatch] = useGlobalState()

  const onClickAccept = () => {
    writeToCookie("gdpr-analytics-enabled", "true")
    // writeToCookie("displayNewsModal", "true")
    window.trackGoogleAnalytics()
    dispatch({ cookieBannerStatus: false })
  }

  const onClickDecline = () => {
    dispatch({ cookieBannerStatus: false })
  }

  return (
    <div>
      {state.cookieBannerStatus ? (
        <AnimationOnVisible
          delay={1000}
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
        >
          <div className="cookie-concent">
            <p>
              We use cookies to ensure that we give you the best experience on
              our website. We also use cookies for website statistics, which is
              used to improve our website. Please click on{" "}
              <u onClick={() => navigate("/privacy-statement")}>this link</u>{" "}
              for more information about how we use cookies on our website.
            </p>
            <div className="cookie-concent__buttons">
              <div className="cookie-concent__buttons__button">
                <Button
                  onClick={() => onClickAccept()}
                  label="ACCEPT"
                  color="white"
                  backgroundColor="var(--main-background-color)"
                />
              </div>
              <div className="cookie-concent__buttons__button">
                <Button
                  onClick={() => onClickDecline()}
                  label="DECLINE"
                  color="white"
                  backgroundColor="var(--main-background-color)"
                />
              </div>
            </div>
          </div>
        </AnimationOnVisible>
      ) : null}
    </div>
  )
}

export default CookieConcent
