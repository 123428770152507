import React from 'react';

import { useGlobalState } from '../global/global';

import '../styles/footer.css';
import { navigate } from 'gatsby';

const Footer = (props) => {
    const [state, dispatch] = useGlobalState();

    const setImageSrc = (imageName) => {
        if (props.location.pathname !== '/') {
            return '/images/dark/' + imageName;
        }

        if (state.lightThemeState) {
            return '/images/light/' + imageName;
        }

        if (!state.lightThemeState) {
            return '/images/dark/' + imageName;
        }
    }

    const handleClick = (id, url, tabIndex = undefined) => {
        if (tabIndex !== undefined) {
            dispatch({ tabIndex })
        }

        if (url) {
            navigate(url);
        }

        const checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });

                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <footer className='footer'>
            <div style={{ color: props.topColor, backgroundColor: props.topBackgroundColor }} className='footer__top'>
                <div className='footer__top__left'>
                    <div className='footer__top__left__content'>
                        <h5 className='footer__top__left__content__title'>DATAUNITOR</h5>
                        <ul className='footer__top__left__content__list'>
                            <li onClick={() => navigate('/about-us')}>About us</li>
                            <li onClick={() => navigate('/contact')}>Contact</li>
                            <li onClick={() => handleClick('team', '/about-us')}>Team</li>
                        </ul>
                    </div>
                    <div className='footer__top__left__content'>
                        <h5 className='footer__top__left__content__title'>PRODUCTS</h5>
                        <ul className='footer__top__left__content__list'>
                            <li onClick={() => navigate('/nginr')}>nginr<sup>®</sup></li>
                            <li onClick={() => navigate('/hymots')}>hymots<sup>®</sup></li>
                        </ul>
                    </div>
                    <div className='footer__top__left__content'>
                        <h5 className='footer__top__left__content__title'>SOLUTIONS</h5>
                        <ul className='footer__top__left__content__list'>
                            <li onClick={() => navigate('/big-data-analytics')}>Big Data Analytics</li>
                            <li onClick={() => handleClick('solutions','/', 1)}>Security and Intelligence</li>
                            <li onClick={() => handleClick('solutions','/', 2)}>Modeling and Simulation</li>
                        </ul>
                    </div>
                    {/* <div className='footer__top__left__content'>
                        <h5 className='footer__top__left__content__title'>SERVICES</h5>
                        <ul className='footer__top__left__content__list'>
                            <li onClick={() => handleClick('software-engineering', '/services')}>Software Engineering</li>
                            <li onClick={() => handleClick('design', '/services')}>Design</li>
                            <li onClick={() => handleClick('modeling-and-simulation', '/services')}>Modeling and Simulation</li>
                            <li onClick={() => handleClick('digital-innovation-management', '/services')}>Digital Innovation Management</li>
                        </ul>
                    </div> */}
                </div>
                <div className='footer__top__right'>
                    <h5 className='footer__top__right__title'>FOLLOW US</h5>
                    <div className='footer__top__right__icons'>
                        <a href='https://www.linkedin.com/company/dataunitor/' target="_blank">
                            <img className='footer__top__right__icons__icon' src={setImageSrc('linkedin.svg')} alt='linkedin' />
                        </a>
                        <a href='https://medium.com/@info.dataunitor' target="_blank">
                            <img className='footer__top__right__icons__icon' src={setImageSrc('medium.svg')} alt='medium' />
                        </a>
                    </div>
                </div>
            </div>
            <div style={{ color: props.bottomColor, backgroundColor: props.bottomBackgroundColor }} className='footer__bottom'>
                <div className='footer__bottom__top'>
                    {/* <h5 className='footer__bottom__top__title'>TERMS & CONDITIONS</h5> */}
                    <h5 onClick={() => navigate('/privacy-statement')} className='footer__bottom__top__title'>PRIVACY STATEMENT</h5>
                    {/* <h5 className='footer__bottom__top__title'>COPYRIGHT NOTIFICATIONS</h5> */}
                </div>
                <p className='footer__bottom__text'>© Copyright 2023 Dataunitor. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
