import React, { useState } from 'react'
import { navigate } from 'gatsby';

import { useGlobalState } from '../global/global';

import ThemeButton from './theme-button';
import HeaderIconMenu from './header-icon-menu';
import AnimationOnVisible from '../global/animation';

import '../styles/header.css';

const Header = (props) => {
    const [state, dispatch] = useGlobalState();
    const [menuIconState, setMenuIconState] = useState(false);

    const setImageSrc = () => {
        if (props.location.pathname === '/') {
            return '/images/dark/dataunitor-beyaz.svg'
        }

        if (state.lightThemeState) {
            return '/images/light/dataunitor.svg'
        }

        if (!state.lightThemeState) {
            return '/images/dark/dataunitor-beyaz.svg'
        }
    }

    const handleClick = (id) => {
        navigate('/');
        var checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <div className='header-wrapper'>
            <header id='header' style={{ color: props.color }} className='header'>
                <AnimationOnVisible delay={300} from={{ opacity: 0, marginTop: -44 }} to={{ opacity: 1, marginTop: 0 }}>
                    <img className='header__logo' onClick={() => navigate('/')} src={setImageSrc()} alt='logo' />
                </AnimationOnVisible>
                <AnimationOnVisible delay={300} from={{ opacity: 0, marginRight: 32 }} to={{ opacity: 1, marginRight: 48 }}>
                    <div className='header__right'>
                        <ul className='header-menu'>
                            <li onClick={() => navigate('/about-us')}>ABOUT US</li>
                            <li onClick={() => handleClick('products')}>PRODUCTS</li>
                            <li onClick={() => handleClick('solutions')}>SOLUTIONS</li>
                            {/* <li onClick={() => handleClick('services')}>SERVICES</li> */}
                            <li onClick={() => navigate('/contact')}>CONTACT</li>
                        </ul>
                        <ThemeButton location={props.location} />
                    </div>
                </AnimationOnVisible>
                <AnimationOnVisible delay={300} from={{ opacity: 0, marginTop: -44 }} to={{ opacity: 1, marginTop: 0 }}>
                    <img onClick={() => setMenuIconState(!menuIconState)} className='header-icon-menu__icon' src='/images/dark/hamburger_menu.svg' alt='hamburger menu' />
                </AnimationOnVisible>
            </header>
            <div className='header-icon-menu-wrapper'>
                {menuIconState ? <HeaderIconMenu location={props.location} closeMenu={() => setMenuIconState(false)} /> : null}
            </div>
        </div>
    );
}

export default Header;