import React from 'react';

import { Spring } from 'react-spring/renderprops';
import VisibilitySensor from 'react-visibility-sensor';

const AnimationOnVisible = props => {
    let isVisibleState = false;
    return (
        <VisibilitySensor partialVisibility={true} minTopValue={props.minTopValue || 0}>
            {({ isVisible }) => {
                if (isVisible) {
                    isVisibleState = true;
                }

                return (
                    <Spring config={{ duration: props.duration }} delay={props.delay || 300} to={isVisible || isVisibleState ? { ...props.default, ...props.to } : { ...props.default, ...props.from }}>
                        {(springProps) => {
                            return (
                                <>
                                    {React.Children.map(props.children, child =>
                                        React.cloneElement(child, { style: { ...springProps } })
                                    )}
                                </>
                            );
                        }}
                    </Spring>
                )
            }}
        </VisibilitySensor>
    );
}

export default AnimationOnVisible;